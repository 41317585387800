body {
  margin: 0;
  padding: 0;
  font-family: "SF Pro Display", -apple-system, BlinkMacSystemFont,
  “Segoe UI”, “Roboto”, “Oxygen”, “Ubuntu”, “Cantarell”,
  “Fira Sans”, “Droid Sans”, “Helvetica Neue”,
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
}
